<template>
  <!-- eslint-disable max-len -->
  <div class="topbar">
    <div class="flex items-center lg:hidden flex-grow">
      <button @click="openSidenav" class="mr-3 h-btn w-btn inline-flex justify-center items-center rounded-md text-white hover:bg-primary-900">
        <MenuIcon size="24" />
      </button>
      <a class="inline-block" @click="goToOldCentrax('dashboard')">
        <img src="/logo-parcelex-white.svg" class="h-7" alt="Logo Parcelex para Negócios" />
      </a>
    </div>
    <Breadcrumbs :pageName="pageName">
      <a class="cursor-pointer" @click="goToOldCentrax('dashboard')">Visão geral</a>
    </Breadcrumbs>
    <div class="ml-auto flex items-center space-x-1.5" v-click-outside="onClickOutside">
      <div class="dropdown-list">
        <button class="p-1 flex items-center rounded-full text-white lg:text-current hover:bg-primary-900 lg:hover:bg-gray-100 h-auto" @click="showDropdown">
          <UserCircleIcon size="24" />
          <span class="ml-2 mr-1 hidden md:inline">{{ user.name }}</span>
          <ChevronDownIcon size="20" />
        </button>
        <div class="dropdown-menu absolute left-auto right-0 lg:left-0 lg:right-auto " :class="openDropdown ? '' : 'hidden'">
          <div class="dropdown-menu-list">
            <!-- <div class="dropdown-menu-item">
              <button class="dropdown-menu-button">
                <CogIcon class="mr-2" size="24"/>
                <span>Configurações</span>
              </button>
            </div> -->
            <div class="dropdown-menu-item">
              <button class="dropdown-menu-button" @click="handleLogout">
                <LogoutIcon class="mr-2" size="24" />
                <span>Sair</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  UserCircleIcon,
  LogoutIcon,
  // CogIcon,
  MenuIcon,
} from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import { Breadcrumbs } from '@/components';
import authUtil from '../util/util.auth';
import globals from '../globals';

export default {
  name: 'Topbar',
  props: {
    pageName: {
      type: String,
      required: true,
    },
  },
  components: {
    Breadcrumbs,
    // CogIcon,
    LogoutIcon,
    MenuIcon,
    UserCircleIcon,
    ChevronDownIcon,
  },
  data: () => ({
    openDropdown: false,
    user: {
      name: null,
    },
  }),
  computed: {
    dashboardURL() {
      const authToken = authUtil.getToken();
      const authUser = authUtil.getUsername();
      return `/dashboard?authToken=${authToken}&authUser=${authUser}`;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    showDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    goToOldCentrax(oldPath) {
      const authToken = authUtil.getToken();
      const authUser = authUtil.getUsername();
      window.location.href = `${globals().centralUrl}${oldPath}?authToken=${authToken}&authUser=${authUser}`;
    },
    handleLogout() {
      this.logout();
    },
    onClickOutside() {
      if (this.openDropdown) this.openDropdown = false;
    },
    openSidenav() {
      this.$emit('sidenavOpen');
    },
  },
  mounted() {
    const username = authUtil.getUsername();
    this.user.name = username;
  },
};
</script>
