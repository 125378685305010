<template>
  <span class="h-screen flex flex-col">
    <div class=" flex justify-center pt-28 items-start h-screen bg-gray-100 md:mx-6">
      <div class="grid gap-3 max-w-md p-5">
        <div
          style="width: fit-content"
          class="rounded-lg border-2 border-dashed border-neutral-gray-300 p-1"
        >
          <img width="32px" height="32px" src="../../../../public/dollar-icon.svg"/>
        </div>
        <div class="flex flex-col gap-3"  v-if="isPending">
          <div class="text-xl font-bold">Ainda não existem repasses recebidos</div>
          <div class=" text-gray-500">Acompanhe as transferências realizadas.</div>
        </div>
        <div class="flex flex-col gap-3" v-else>
          <div class="text-xl font-bold">Ainda não existem repasses a receber</div>
          <div class=" text-gray-500">Acompanhe as transferências a serem realizadas.</div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: 'NoTransferArea',
  props: {
    isPending: Boolean,
  },
};
</script>
