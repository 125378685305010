<template>
  <div>
    <div
      class="fixed top-0 left-0 bottom-0 right-0 bg-black transition-all duration-500 z-20"
      :class="!opened ? 'invisible opacity-0' : 'visible opacity-60'"
    />

    <nav
      class="sidenav"
      :class="opened ? 'transform-none translate-x-full' : ''"
    >
      <div class="sidenav-header">
        <img
          src="/logo-parcelex-white.svg"
          class="h-7"
          alt="Logo Parcelex para Negócios"
        />
        <button
          @click="openSidenav"
          class="absolute top-1/2 transform -translate-y-1/2 right-3 lg:hidden ml-auto
          text-white h-8 w-8 bg-white bg-opacity-10 inline-flex justify-center
          items-center rounded-md hover:bg-black hover:bg-opacity-20"
        >
          <XIcon size="20" />
        </button>
      </div>
      <div>
        <div class="select dropdown" v-click-outside="onClickOutside">
          <button
            @click="showDropdown"
            class="h-auto py-3 px-4 w-full text-white mb-3 flex items-center
            hover:bg-black hover:bg-opacity-10"
          >
            <div
              class="relative w-9 h-9 mr-2.5 flex-shrink-0 rounded-full bg-white overflow-hidden"
            >
              <img
                class="object-contain h-full w-full"
                :src="currentRetailer ? currentRetailer.logo : ''"
              />
            </div>
            <div class="font-semibold flex-grow text-left">
              {{ currentRetailer ? currentRetailer.name : '' }}
            </div>
            <ChevronDownIcon class="flex-shrink-0" size="20" />
          </button>
          <div
            class="dropdown-menu left-0 right-0 mx-auto w-60 border-0"
            :class="openDropdown ? '' : 'hidden'"
          >
            <div class="px-2 pb-2">
              <div class="input-group has-icon">
                <div class="input-control">
                  <div class="input-icon">
                    <SearchIcon size="20" />
                  </div>
                  <input
                    v-model="searchRetailerName"
                    type="text"
                    class="input-field text-sm h-9"
                    placeholder="Buscar"
                  />
                </div>
              </div>
            </div>
            <div class="dropdown-menu-list max-h-64 overflow-y-auto">
              <div class="dropdown-menu-list">
                <div
                  v-for="retailer in filteredRetailers"
                  :key="retailer._id"
                  class="dropdown-menu-item"
                  :class="{ 'is-active': currentRetailer._id === retailer._id }"
                  @click="handleChangeRetailer(retailer)"
                >
                  <button class="dropdown-menu-button">
                    <span>{{ retailer.name }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidenav-section px-3 mb-4">
        <menu class="sidenav-menu list-none px-0">
          <li
            v-for="(route, index) in filteredRoutes"
            :key="index"
            class="sidenav-menu-item cursor-pointer"
            :class="{ 'is-active': $route.name === route.value }"
          >
            <a
              v-if="route.isOldCentrax"
              :href="getRoute(route)"
              class="sidenav-menu-link"
            >
              <component :is="route.icon" size="24" />
              <span class="ml-4">{{ route.text }}</span>
            </a>
            <router-link
              v-else
              :to="{ name: getRoute(route) }"
              class="sidenav-menu-link"
            >
              <component :is="route.icon" size="24" />
              <span class="ml-4">{{ route.text }}</span>
            </router-link>
          </li>
        </menu>
        <menu v-if="productsRoutes.length > 0" class="sidenav-menu list-none px-0">
          <h2 class="px-3 py-1 submenu-title uppercase text-primary-100"> Meus Produtos </h2>
          <li
            v-for="(route, index) in productsRoutes"
            :key="index"
            class="sidenav-menu-item cursor-pointer"
            :class="{ 'is-active': $route.name === route.value }"
          >
            <a
              v-if="route.isOldCentrax"
              :href="getRoute(route)"
              class="sidenav-menu-link"
            >
              <component :is="route.icon" size="24" />
              <span class="ml-4">{{ route.text }}</span>
            </a>
            <router-link
              v-else
              :to="{ name: getRoute(route) }"
              class="sidenav-menu-link"
            >
              <component :is="route.icon" size="24" />
              <span class="ml-4">{{ route.text }}</span>
            </router-link>
          </li>
        </menu>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  SearchIcon,
  LinkIcon,
  ViewGridIcon,
  XIcon,
  ShoppingCartIcon,
  CurrencyDollarIcon,
  DocumentReportIcon,
} from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import authUtil from '../util/util.auth';
import globals from '../globals';

export default {
  name: 'sidenav',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ViewGridIcon,
    LinkIcon,
    XIcon,
    SearchIcon,
    ShoppingCartIcon,
    CurrencyDollarIcon,
    DocumentReportIcon,
    ChevronDownIcon,
  },
  data: () => ({
    searchRetailerName: null,
    openDropdown: false,
    routes: [
      {
        value: 'orders',
        text: 'Pedidos',
        icon: 'ShoppingCartIcon',
        isOldCentrax: false,
      },
      {
        value: 'transfers',
        text: 'Repasses',
        icon: 'CurrencyDollarIcon',
        isOldCentrax: false,
      },
      {
        value: 'payment-link-list',
        text: 'Link de pagamento',
        icon: 'LinkIcon',
        isOldCentrax: false,
      },
      {
        value: 'report-list',
        text: 'Relatórios',
        icon: 'DocumentReportIcon',
        isOldCentrax: false,
      },
    ],
    productsRoutePaths: ['payment-link-list'],
  }),
  computed: {
    ...mapState('retailer', ['retailers', 'currentRetailer']),
    filteredRetailers() {
      return this.retailers.filter(this.hasSearchedName);
    },
    filteredRoutes() {
      const routesCopy = this.routes.filter(
        (route) => this.productsRoutePaths.find((product) => route.value !== product),
      );
      // If in transfer or transfers-pending, should highligh repasses on side menu.
      if (this.$route.name === 'transfers-pending') {
        routesCopy[1].value = this.$route.name;
      }
      return routesCopy;
    },
    productsRoutes() {
      const productRoutes = this.routes.filter(
        (item) => this.productsRoutePaths.find((route) => {
          if (route === 'payment-link-list') {
            return this.hasPaymentLink(this.currentRetailer) ? item.value === route : false;
          }
          return item.value === route;
        }),
      );

      return productRoutes;
    },
  },
  methods: {
    ...mapActions('retailer', ['getRetailers', 'changeRetailer']),
    onClickOutside() {
      if (this.openDropdown) this.openDropdown = false;
    },
    showDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    getRoute({ value, isOldCentrax }) {
      if (!isOldCentrax) return value;
      const authToken = authUtil.getToken();
      const authUser = authUtil.getUsername();
      return `${
        globals().centralUrl
      }${value}?authToken=${authToken}&authUser=${authUser}`;
    },
    handleChangeRetailer(retailer) {
      this.changeRetailer({ retailer });
      if (!this.hasPaymentLink(retailer)) { this.$router.push({ name: 'orders' }); }
      this.openDropdown = false;
      this.searchRetailerName = null;
      this.$emit('onChangeRetailer', retailer);
    },
    openSidenav() {
      this.$emit('sidenavOpen');
    },
    hasPaymentLink(retailer) {
      return !!(retailer?.products && retailer?.products.includes('paymentLink'));
    },
    hasSearchedName(retailer) {
      if (!this.searchRetailerName) return true;
      const searchRetailerNameInLowerCase = this.searchRetailerName.toLowerCase();
      return (
        retailer.name.toLowerCase().indexOf(searchRetailerNameInLowerCase)
          !== -1 || !searchRetailerNameInLowerCase
      );
    },
  },
  mounted() {
    this.getRetailers();
  },
};
</script>
<style lang="postcss" scoped>
.submenu-title {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.2px;
  @apply font-semibold;
}
</style>
