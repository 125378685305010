<!-- eslint-disable max-len -->
<template>
  <div class="relative h-full">
    <div class="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-60 transition-opacity duration-500 z-30"
      :class="openModal && selectedTransfer.id  ? '' : 'invisible opacity-0'"></div>
    <div class="drawer" :class="openModal && selectedTransfer.id ? 'translate-x-0' : 'translate-x-full'">
      <div class="flex-grow overflow-x-auto">
        <header class="drawer-header flex items-center py-6 justify-between">
          <h3 class="uppercase tracking-widest text-sm font-semibold">
            Resumo da transferência
          </h3>
          <button aria-label="Close" class="button button-plain button-icon hover:border-gray-200 -mr-3"
            @click="showModal">
            <XIcon size="24" />
            <span class="sr-only">
              Fechar
            </span>
          </button>
        </header>
        <section class="divide-y-4 divide-gray-100">
          <div class="drawer-section pb-6">
            <dl class="divide-y divide-gray-200">
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Código</dt>
                <dd class="col-span-2">
                  <div class="break-all">{{ selectedTransfer.id }}</div>
                </dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Data do envio</dt>
                <dd class="col-span-2">{{ selectedTransfer.transferedAt | dateFilter }}</dd>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4">
                <dt class="text-gray-500">Valor</dt>
                <dd class="col-span-2">{{ selectedTransfer.finalAmount | moneyFilter }}</dd>
              </div>
            </dl>
          </div>
          <div class="drawer-section py-6" v-if="selectedTransfer.transactions && selectedTransfer.transactions[0]._id">
              <h3 class="uppercase tracking-widest text-sm font-semibold mb-6">
                Pedidos
              </h3>

            <ul class="space-y-4">
              <li
                class="border mb-4 rounded-xl"
                v-for="(transaction, index) in selectedTransfer.transactions" :key="index">
                <div class="flex justify-between items-center p-5 cursor-pointer hover:bg-gray-100" :class="showTransferDetailsId === transaction._id ? 'rounded-t-xl' : 'rounded-xl' " @click="showTransferDetailsHandler(transaction)">
                  <div class="flex flex-col">
                    <div class="flex">
                      <span class="text-gray-500 text-sm w-32">Nº pedido:</span>
                      <span class="text-sm"> {{ transaction.orderId }} </span>
                    </div>
                    <div class="flex">
                      <span class="text-gray-500 text-sm w-32">Realizado em:</span>
                      <span class="text-sm"> {{ transaction.purchasedDate | dateFilter }} </span>
                    </div>
                  </div>
                  <Badge
                      v-if="transaction.status === 'CANCELED'"
                      :kind="badgeKindStatus[transaction.status] || 'default'"
                    >
                      {{ statusOptions[transaction.status] }}
                    </Badge>
                  <button class="h-6 w-6 inline-flex justify-center items-center rounded-full">
                    <ChevronDownIcon size="20" class="transition transform" :class="showTransferDetailsId === transaction._id && 'rotate-180' "/>
                  </button>
                </div>
                <ul class="divide-y border-t flex flex-col items-center" v-if="showTransferDetailsId === transaction._id">
                  <li class="flex gap-3 p-4 w-full" v-for="(product, index) in transaction.products"
                    :key="index">
                    <div class="flex flex-grow gap-3">
                      <img :src="product.image" class="w-12" />
                      <div class="flex flex-col">
                        <span class="text-sm">{{ product.name }} </span>
                        <span class="text-sm text-gray-500">Qtd: {{ product.amount }}</span>
                      </div>
                    </div>
                    <div class="text-sm">
                      {{ product.value | moneyFilter }}
                    </div>
                  </li>
                  <li class="py-3 px-4 bg-gray-100 w-full rounded-b-xl" v-if="transaction.value">
                    <div class="flex justify-between pt-1" v-if="transaction.totalOrder && transaction.retentionMdr">
                      <span class="text-sm text-gray-500">Total bruto</span>
                      <span class="text-sm">{{ transaction.totalOrder | moneyFilter }} </span>
                    </div>
                    <div class="flex justify-between pt-1" v-if="transaction.retentionMdr">
                      <span class="text-sm text-gray-500">Taxa de administração</span>
                      <span class="text-sm text-red-600">- {{ transaction.retentionMdr | moneyFilter }} </span>
                    </div>
                    <div class="flex justify-between pt-1">
                      <span class="text-sm text-gray-500">Total recebido</span>
                      <span class="text-sm">{{ transaction.value | moneyFilter }}</span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <!-- {{ transaction.finalAmount | moneyFilter }} -->
    <Sidenav @sidenavOpen="handleSidenavOpen" @onChangeRetailer="handleChangeRetailer" :opened="sidenavOpen" />
    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <Topbar @sidenavOpen="handleSidenavOpen" pageName="Recebidos" />
      <header class="border-b-2 border-gray-200 bg-white">
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-5 px-4 lg:px-6 pt-3 pb-4 lg:pb-5">
          <div class="flex items-center flex-grow">
            <div class="flex-shrink-0">
              <img class="w-btn mr-3" src="brand-icon-dollar.svg" />
            </div>
            <h1 class="font-bold text-xl">Repasses</h1>
          </div>
        </div>
        <nav>
          <ul class="tabs px-4 lg:px-6">
            <li class="tab-item is-active">
              <a href="repasses" class="tab-link">
                Recebidos
              </a>
            </li>
            <li class="tab-item">
              <a href="repasses-pendentes" class="tab-link">
                A receber
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <!-- No transfers found -->
      <main v-if="!transfers">
        <NoTransferArea isPending />
      </main>
      <div v-if="transfers"
        class="flex flex-shrink-0 gap-3 py-5 px-3 lg:px-6 overflow-x-auto lg:overflow-visible sticky top-0 z-10 bg-white">
        <div class="flex flex-grow gap-5">
          <div class="w-full lg:w-72">
            <div class="input-group has-icon">
              <div class="input-control">
                <div class="input-icon">
                  <SearchIcon size="24" />
                </div>
                <input
                  v-on:keyup.enter="filterBySearch('enter')"
                  v-on:keyup="waitTypingFinish"
                  v-model="searchInput"
                  type="text"
                  class="input-field"
                  placeholder="Buscar nº transferência"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Calendar and filter icons -->
        <div class="hidden lg:block flex-shrink-0">
          <div class=" flex gap-5">
            <DatePicker class="w-80" v-model="selectedDates" @input="handleDatePick" :defaultDate="defaultDate"
              placeholder="Selecionar" mode="range" maxDate="today" icon white />
          </div>
        </div>

        <div class="flex gap-3 flex-shrink-0 lg:hidden">
          <button class="button button-icon" id="datePickerToggle">
            <CalendarIcon size="24" />
          </button>
        </div>
        <!-- End of calendar and filter icons -->

      </div>
      <main v-if="transfers" class="relative lg:px-6 flex-grow overflow-x-auto">
        <!-- Loading component -->
        <div class="bg-white bg-opacity-90 absolute top-0 right-0 left-0 bottom-0 z-10 flex flex-col items-center pt-36"
          :class="{ hidden: !searchingTransfers }">
          <svg role="status" class="w-12 h-12 mr-2 text-gray-300 animate-spinner" viewBox="0 0 100 101" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"></path>
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#20627A"></path>
          </svg>
        </div>
        <table class="table table-compact" @sort="fillTransfers">
          <thead class="text-gray-500 text-left">
            <tr>
              <th scope="col">
                <span class="align-middle">Transferência</span>
              </th>
              <th scope="col" class="cursor-pointer" @click="handleSortTransfers('createdAt')">
                <img class="inline-block -ml-5 mr-1" :src="handleSortTransfersIcon('createdAt')" />
                <span class="align-middle">Data</span>
              </th>
              <th scope="col" class="cursor-pointer text-right" @click="handleSortTransfers('totalAmount')">
                <img class="inline-block -ml-5 mr-1" :src="handleSortTransfersIcon('totalAmount')" />
                <span class="align-middle">Valor</span>
              </th>
              <th scope="col" class="text-right">
                <span class="sr-only">Visualizar</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(transfer, index) in transfers" :key="index">
              <td class="py-1 px-3 whitespace-nowrap">
                {{ transfer.id }}
              </td>
              <td class="py-1 px-3 whitespace-nowrap">
                <div class="text-sm text-gray-900">{{ transfer.transferedAt | dateFilter }}</div>
                <div class="text-xs text-gray-500">
                  {{ transfer.transferedAt | hourFilter }}
                </div>
              </td>
              <td class="py-1 px-3 text-right">{{ transfer.finalAmount | moneyFilter }}</td>
              <td class="py-1 px-3 text-right">
                <button class="button button-plain button-icon text-gray-500 hover:border-gray-200" @click="showModal">
                  <EyeIcon size="24" @click="selectedTransferHandler(transfer)" />
                </button>
              </td>
            </tr>
            <tr v-if="transfers.length === 0">
              <td colspan="100%" class="text-center">
                <div class="py-4">Nenhum resultado encontrado</div>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
      <footer v-if="transfers.length !== 0" class="bg-white lg:sticky lg:bottom-0 w-full z-10 lg:px-6">
        <div class="flex justify-end gap-2 bg-gray-100 px-4 py-2">
          <div class="text-gray-500 text-sm">
            Total recebido:
          </div>
          <div class="font-semibold text-right text-sm">
            {{ totalAmount | moneyFilter }}
          </div>
        </div>
        <Pagination v-if="totalAmount !== 0" v-model="currentPage" :itemsPerPage="transfersPerPage"
          :numberOfItems="totalOfTransfers" @pagination="fillTransfers" />
      </footer>
    </div>
  </div>
</template>

<script>
import { SearchIcon, EyeIcon, CalendarIcon, XIcon, ChevronDownIcon } from '@vue-hero-icons/outline';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

import {
  Badge,
  DatePicker,
  Pagination,
} from '@/components';
import StringMixin from '@/mixins/string';

import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';
import NoTransferArea from './NoTransferArea.vue';

export default {
  name: 'transfers',
  metaInfo: {
    title: 'Repasses',
  },
  mixins: [StringMixin],
  components: {
    Badge,
    Pagination,
    SearchIcon,
    DatePicker,
    EyeIcon,
    XIcon,
    Sidenav,
    Topbar,
    // ChevronUpIcon,
    ChevronDownIcon,
    CalendarIcon,
    NoTransferArea,
  },
  data: () => ({
    channelSelected: '',
    sidenavOpen: false,
    openModal: false,
    showFilters: false,
    searchingTransfers: false,
    currentSearchTimeout: null,
    filterChannelSelected: '',
    maxSearchTimeout: 800,
    openConfigDrawer: false,
    showTransferDetailsId: null,
    defaultDate: [],
    searchInput: '',
    sort: { createdAt: -1 },
    channelOptions: {
      '': 'Todos',
      paymentLink: 'Link de pagamento',
      checkout: 'Checkout',
      pdp: 'Pdp',
      site: 'Site',
      imobiliaria: 'Imobiliária',
    },
    badgeKindStatus: {
      ABANDONED: 'danger',
      CANCELED: 'danger',
      CLOSED: 'default',
      OPEN: 'success',
      PENDING: 'warning',
    },
    statusOptions: {
      '': 'Todos',
      ABANDONED: 'Expirado',
      CANCELED: 'Cancelado',
      CLOSED: 'Fechado',
      OPEN: 'Contratado',
      PENDING: 'Pendente',
    },
    selectedDates: [],
    transfersPerPage: 15,
    currentPage: 0,
    currentSort: {
      headerIndex: 1,
      direction: 'descending',
    },
    selectedTransfer: {},
  }),
  computed: {
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('finances', ['transfers', 'totalOfTransfers']),
    ...mapState('finances', ['totalAmount']),
  },
  methods: {
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('finances', ['getTransfers']),
    showModal() {
      this.openModal = !this.openModal;
    },
    async handleChangeRetailer() {
      await this.fillTransfers();
    },
    handleSidenavOpen() {
      this.sidenavOpen = !this.sidenavOpen;
    },
    handleDatePick() {
      if (this.selectedDates.length === 2) this.fillTransfers();
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    handleSortTransfers(type) {
      const sortValue = Object.values(this.sort)[0];

      if (sortValue === -1) {
        this.sort = { [type]: 1 };
      } else {
        this.sort = { [type]: -1 };
      }
      this.fillTransfers();
    },
    handleSortTransfersIcon(type) {
      const sortValue = Object.values(this.sort)[0];
      const sortKey = Object.keys(this.sort)[0];

      let sortPic = 'sort.svg';

      if (type === sortKey && sortValue === 1) {
        sortPic = 'sort-asc.svg';
      } else if (type === sortKey && sortValue === -1) {
        sortPic = 'sort-desc.svg';
      }
      return sortPic;
    },
    showTransferDetailsHandler(data) {
      if (this.showTransferDetailsId === data._id) {
        this.showTransferDetailsId = null;
        return;
      }
      this.showTransferDetailsId = data._id;
    },
    async handleStatusSelect(newValue) {
      if (newValue === this.channelSelected) return;
      this.channelSelected = newValue;
      await this.fillTransfers();
    },
    async filterHandleStatusSelect(newValue) {
      if (newValue === this.filterChannelSelected) return;
      this.filterChannelSelected = newValue;
    },
    async applyFilter() {
      if (this.channelSelected !== this.filterChannelSelected) {
        this.channelSelected = this.filterChannelSelected;
      }
      await this.fillTransfers();
      this.openConfigDrawer = false;
    },
    showConfigDrawer() {
      this.openConfigDrawer = !this.openConfigDrawer;
    },
    selectedTransferHandler(data) {
      this.selectedTransfer = data;
    },
    async fillTransfers() {
      const [startDate, endDate] = this.selectedDates;
      if (
        this.currentRetailer
        && this.currentRetailer._id
        && startDate instanceof Date
        && endDate instanceof Date
      ) {
        this.searchingTransfers = true;
        await this.getTransfers({
          startDate: moment(new Date(startDate)).startOf('day').toISOString(),
          endDate: moment(new Date(endDate)).endOf('day').toISOString(),
          retailerId: this.currentRetailer?._id,
          search: this.searchInput,
          limit: this.transfersPerPage,
          offset: this.currentPage,
          channel: this.channelSelected,
          sort: this.sort,
        });
        this.searchingTransfers = false;
      }
    },
    async filterBySearch(type) {
      this.filterBySearchType = type;
      await this.fillTransfers();
    },
    waitTypingFinish() {
      if (this.currentSearchTimeout) clearTimeout(this.currentSearchTimeout);

      this.currentSearchTimeout = setTimeout(() => {
        if (this.filterBySearchType === 'enter') {
          this.filterBySearchType = null;
          return;
        }
        this.filterBySearch('timeout');
      }, this.maxSearchTimeout);
    },
  },
  async mounted() {
    if (this.currentRetailer && this.currentRetailer._id) {
      this.selectedDates = [moment().subtract(1, 'month').toDate(), moment().toDate()];
      await this.setIsLoading({ isLoading: true });
      await this.fillTransfers();
      await this.setIsLoading({ isLoading: false });
    }
  },
  async beforeMount() {
    this.defaultDate = [
      moment().subtract(1, 'month').format('DD/MM/YYYY'),
      moment().format('DD/MM/YYYY'),
    ];
  },
};
</script>
